import React, { useMemo, useState } from 'react'
import { romanize } from '../../utils/romanize'

import './styles.scss'
import Modal from '../Modal'

const TrackItem = (track) => {
  const numb = useMemo(() => romanize(track.id), [track.id])
  const [open, setOpen] = useState(false)
  return (
    <>
      <div className="track">
        <div className="track-index">{numb}.</div>
        <div className="track-name">{track.name}</div>
        <div className="track-lyrics">
          <button
            type="button"
            className="track-link"
            disabled={!track.lyrics}
            onClick={() => setOpen(true)}
          >
            Lyrics
          </button>
        </div>
      </div>
      <Modal
        open={open}
        title={track.name}
        content={track.lyrics}
        index={numb}
        handleClose={() => setOpen(false)}
      />
    </>
  )
}

export default TrackItem
