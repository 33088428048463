import React from 'react'

import './styles.scss'
import logo2 from '../../images/logo-label@2x.png'
import logo from '../../images/logo-label@1x.png'

const BuyServices = () => (
  <section className="service-section">
    <a
      href="https://elderblood.bandcamp.com/"
      className="service-title"
      target="_blank"
    >
      Listen Online
    </a>
    <div className="service-block">
      <div className="service-block-item">
        <a
          href="https://drakkar666.com/"
          className="label-logo"
          target="_blank"
        >
          <picture>
            <source srcSet={`${logo2} 2x, ${logo} 1x`} />
            <img src={logo} alt="logo" />
          </picture>
        </a>
      </div>
      <div className="service-block-border" />
      <div className="service-block-item">
        <div>Digipack CD</div>
        <a href="https://drakkar666.com/" target="_blank">
          Buy Online
        </a>
      </div>
      <div className="service-block-border" />
      <div className="service-block-item">
        <div>Digital Copy</div>
        <a href="https://elderblood.bandcamp.com/" target="_blank">
          Buy Online
        </a>
      </div>
    </div>
  </section>
)

export default BuyServices
