import React from 'react'

import './styles.scss'

import socials from '../../data/socials.json'

const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <footer className="footer">
      <div className="footer-inner">
        <div className="footer-top">
          <div className="footer-top-item">
            <h4>Contacts</h4>
            <a href="mailto:elderblood218@gmail.com">elderblood218@gmail.com</a>
          </div>
          <div className="footer-top-item">
            <h4>Social</h4>
            <div>
              {socials &&
                socials.map((social) => (
                  <a key={social.id} href={social.url} target="_blank">
                    {social.title}
                  </a>
                ))}
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          © Elderblood, {year}. All Rights Reserved.
        </div>
      </div>
    </footer>
  )
}

export default Footer
