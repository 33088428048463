import React from 'react'
import TrackItem from '../TrackItem'
import cover from '../../assets/video/cover.mp4'

import trackList from '../../data/tracks.json'

import './styles.scss'

const AlbumInfo = () => (
  <section className="info-section">
    <div className="video-wrapper">
      <video loop autoPlay width={760} muted className="cover-video">
        <source src={cover} type="video/mp4" />
        Sorry, your browser doesn&apos;t support embedded videos.
      </video>
    </div>

    <div className="track-list">
      {trackList &&
        trackList.map((track) => <TrackItem {...track} key={track.id} />)}
    </div>
  </section>
)

export default AlbumInfo
