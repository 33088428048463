import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'classnames'
import Portal from '../Portal'
import close from '../../images/close.svg'
import './styles.scss'

const Modal = ({ open, content, title, handleClose, index }) => {
  const [active, setActive] = useState(false)
  const backdrop = useRef(null)

  useEffect(() => {
    const { current } = backdrop

    const transitionEnd = () => setActive(open)

    const keyHandler = (e) => [27].indexOf(e.which) >= 0 && handleClose()

    const clickHandler = (e) => e.target === current && handleClose()

    if (current) {
      current.addEventListener('transitionend', transitionEnd)
      current.addEventListener('click', clickHandler)
      window.addEventListener('keyup', keyHandler)
    }

    if (open) {
      window.setTimeout(() => {
        document.activeElement.blur()
        setActive(open)
        document.querySelector('#___gatsby').setAttribute('inert', 'true')
      }, 10)
    }

    return () => {
      if (current) {
        current.removeEventListener('transitionend', transitionEnd)
        current.removeEventListener('click', clickHandler)
      }

      document.querySelector('#___gatsby').removeAttribute('inert')
      window.removeEventListener('keyup', keyHandler)
    }
  }, [open, handleClose, backdrop])

  return (
    <>
      {(open || active) && (
        <Portal className="modal-portal">
          <div
            className={clsx('modal', active && open && 'active')}
            ref={backdrop}
          >
            <div className="modal-content">
              <div className="modal-content-top">
                <div className="index">{index}.</div>
                <h3 className="title">{title}</h3>
                <button onClick={handleClose} className="close-button">
                  <img src={close} alt="close" />
                </button>
              </div>
              <div className="content">
                {content.split('\n').map((item, i) => (
                  <span key={i}>
                    {item} <br />
                  </span>
                ))}
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  )
}

Modal.propTypes = {
  open: PropTypes.bool,
  content: PropTypes.string,
  title: PropTypes.string,
  handleClose: PropTypes.func,
  index: PropTypes.string,
}

export default Modal
