import React from 'react'
import ReactPlayer from 'react-player'
import PropTypes from 'prop-types'

import cover from '../../images/cover.png'

import './styles.scss'

console.log(cover)

const VideoItem = ({ videoSrcURL, videoTitle }) => (
  <div className="video">
    <ReactPlayer url={videoSrcURL} width="100%" light poster={cover} />
  </div>
)

VideoItem.propTypes = {
  videoSrcURL: PropTypes.string,
  videoTitle: PropTypes.string,
}
export default VideoItem
