import React from 'react'
import Slider from 'react-slick'
import VideoItem from '../VideoItem'
import videos from '../../data/videos.json'

import './styles.scss'

const Videos = () => {
  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '220px',
    slidesToShow: 1,
    speed: 500,
    arrows: false,
    dots: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerMode: false,
          centerPadding: 0,
          arrows: false,
          dots: false,
          variableWidth: false,
        },
      },
    ],
  }

  return (
    <section className="video-section">
      <h3>Latest Videos</h3>

      <Slider {...settings}>
        {videos &&
          videos.map((video) => (
            <div className="video-item" key={video.id}>
              <VideoItem videoSrcURL={video.src} videoTitle={video.title} />
            </div>
          ))}
      </Slider>
    </section>
  )
}

export default Videos
