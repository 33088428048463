import React from 'react'
import { ReactFlashlight } from 'react-flashlight'
import clsx from 'classnames'
import { isMobile } from 'react-device-detect'
import logo from '../../images/logo@1x.png'
import logo2 from '../../images/logo@2x.png'
import './styles.scss'

const Header = () => (
  <header>
    <ReactFlashlight
      enabled={!isMobile}
      initialPosition={{
        x: 0,
        y: 0,
      }}
      darkness={0.84}
      size={300}
    >
      <div className={clsx('header', isMobile ? 'mask' : null)}>
        <div className="header-logo">
          <picture>
            <source srcSet={`${logo2} 2x, ${logo} 1x`} />
            <img src={logo} alt="logo" />
          </picture>
        </div>
        <div className="header-title">
          <div className="header-title__top">NEW ALBUM</div>
          <h1>ACHRONY</h1>
          <div className="header-title__date">IX.VII.MMXXI</div>
        </div>
      </div>
    </ReactFlashlight>
  </header>
)

export default Header
