import * as React from 'react'
import { Helmet } from 'react-helmet'
import Header from '../components/Header'
import AlbumInfo from '../components/AlbumInfo'
import BuyServices from '../components/BuyServices'
import Videos from '../components/Videos'
import Footer from '../components/Footer'

import '../styles/styles.scss'

// markup
const IndexPage = () => (
  <main>
    <Helmet title="Elderblood | Achrony" defer={false} />
    <Header />
    <AlbumInfo />
    <BuyServices />
    <Videos />
    <Footer />
  </main>
)

export default IndexPage
